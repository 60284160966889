// src/components/Heading.js

import React from 'react';
import "../styles.css";

function Heading() {
    return (
        <h1 className="subpixel-antialiased heading text-5xl font-bold text-black mt-5">Ultra Sharp</h1>
    );
}

export default Heading;
