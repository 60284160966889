import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles.css";
import Navbar from "./components/Navbar";
import Heading from "./components/Heading";
import Home from "./pages/Home";
import Footer from "./components/Footer";

function App() {
  return (
    <section className="flex flex-col h-screen">
      <Router>
        <Heading />
        <Navbar />
        <Home />
        <Footer />
      </Router>
    </section>
  );
}

export default App;

