// src/pages/Home.js

import React from "react";
import Content from "../components/Content";

const homePageText =
  "Discover precision and excellence with our premium Shark Fin, Above, and Kissaki shears, expertly crafted for hair stylists. Elevate your craft with tools that define quality and durability. Our certified sharpening service ensures your tools maintain their pristine condition without voiding the manufacturer's warranty. Experience the convenience of local sharpening paired with the reliability of direct purchasing from a trusted source. Join the ranks of top stylists who choose our services for superior performance and longevity. Invest in your artistry – choose the best tools and sharpening service to keep your creativity sharp and unbounded.";
function Home() {
  return (
    <div className="text-3xl grid grid-cols-1 md:grid-cols-2 md:text-xl gap-x-8 gap-y-8">
      <Content title="Sales And Service" textContent={homePageText} />
      <div className="m-2">
        <Content title="Shark Fin" />
        <div className="flex justify-center">
          <img className="rounded-2xl" src="SharkfinMainImage.png" alt="" />
        </div>
      </div>

      <div className="m-2">
        <Content title="Above" />
        <div className="flex justify-center">
          <img className="rounded-2xl" src="AboveMainImage.png" alt="" />
        </div>
      </div>

      <div className="m-2">
        <Content title="Kissaki" />
        <div className="flex justify-center">
          <img className="rounded-2xl" src="KissakiMainImage.png" alt="" />
        </div>
      </div>
    </div>
  );
}

export default Home;

