import React from "react";

export default function Content(props) {
  return (
    <div className="mt-4 text-center justify-center items-center">
      <div className="font-bold">{props.title}</div>
        {props.textContent && <div className="m-4 text-justify">{props.textContent}</div>}
        

      
    </div>
  );
}
